<template>
  <div>
    <ArticleContentVue />
    <div class="button">
      <a @click="SendMialbox">
        <el-button type="primary" id="toSendPage">我要留言</el-button>
      </a>
      <a @click="EmailListS">
        <el-button type="success">我的信件</el-button>
      </a>
    </div>
  </div>
</template>

<script>
import ArticleContentVue from '../pages/HomePage/ArticleContent.vue';
export default {
  name: 'IntroducePage',
  components: {
    ArticleContentVue
  },
  methods: {
    SendMialbox(){
      console.log(this.$store.state.token);
      if (this.$store.state.token==='null'||this.$store.state.token==='undefined'){
        this.open();
      } else {
        this.$router.push({
          name: 'SendMialbox'
        });
      }
    },
    EmailListS(){
      console.log(this.$store.state.token);
      if (this.$store.state.token==='null'||this.$store.state.token==='undefined'){
        this.open();
      } else {
        this.$router.push({
          name: 'EmailListS'
        });
      }
    },
    open(){
      this.$confirm('请先登录，是否跳转到登录页面', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push({
          name: 'LoginPage'
        });
      }).catch(function(){
        return;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  margin: 0 auto;
  padding-bottom: 10px;
  width: 245px;
  a:nth-child(1) {
    button {
      margin-right: 20px;
    }
  }
}
</style>