<template>
  <div id="article">
    <h3>河南科技学院校长信箱欢迎您！</h3>
    尊敬的来信人：
    <p class="introduct">
      您好！为进一步增进学校领导与师生员工的密切联系，更好地解决师生普遍关心的实际问题，学校专门设立了“校长信箱”，现将有关事项告知如下：
    </p>
    <ul class="orderlist">
      <li>1. 学校欢迎广大师生员工在学校建设与发展、提升学校核心竞争力等方面多提宝贵意见和建议。</li>
      <li>
        2.
        来信请提供姓名、邮箱、电话号码等真实有效的个人信息，凡未注明真实姓名和有效联系方式的，只作一般工作建议参考，不予回复。
      </li>
      <li>
        3.
        反映问题须客观真实，注意文明用语，凡出现有侮辱人格、恶意攻击、不健康及其他法律禁止的内容，将直接删除、不予答复。
      </li>
      <li>4. 本信箱不受理应当通过诉讼、仲裁等法定途径解决的问题。</li>
      <li>5. 如在本信箱提交意见和建议，即表明您已阅读并接受了上述说明事项。</li>
    </ul>
    <p class="introduct">感谢您对我们工作的支持和配合！谢谢您的合作！</p>
    <p class="introduct">如有疑问，请联系校长办公室行政与信访科。联系电话：0373-3040395。</p>
  </div>
</template>

<script>
export default {
  name: 'ArticleContent'
};
</script>

<style lang="scss" scoped>
#article {
  margin-top: 98px;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 35px;

  @media screen and (max-width: 1300px) {
    margin-top: 0;
  }
  h3 {
    text-align: center;
  }
  .orderlist {
    list-style: none;
    li {
      text-indent: 2em;
    }
  }
  .introduct {
    text-indent: 2em;
  }
}
</style>
